
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormTextarea from "@/components/form/Textarea.vue";

import { Patient } from "@/store/patient/types";
import { formatCPFToLGPD } from "@/libs/utils";

const VGuideValidate = defineComponent({
  name: "VGuideValidate",
  components: { CModal, CFormInputText, CFormTextarea },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const _id = Number(route.params._id.toString());
    const contactType = route.name?.toString().replace("guide-contact-", "");
    useHead({ title: `Entrar em contato via ${contactType} | obmed` });

    const guide = computed(() => store.state.guide.list.find((item) => item.id_guia_eletronica === _id));
    const patient = ref<Patient | null>(null);
    const message = ref("");
    const loading = reactive({ submit: false });

    async function searchPatients() {
      const response = await store.dispatch("searchPatients", { search: { nr_cpf: guide.value?.nr_cpf_paciente } });

      if (response?.data?.results?.length) patient.value = response.data.results[0];
      else {
        store.commit("addToast", { summary: "Não conseguimos encontrar o paciente", severity: "error" });
        handleClose();
      }
    }

    function handleClose() {
      router.back();
    }

    async function onSubmit() {
      if (!patient.value) return;

      loading.submit = true;
      const response =
        contactType === "whatsapp"
          ? await store.dispatch("sendMessageByWhatsApp", {
              numbers: [patient.value.nr_telefone],
              message: message.value,
            })
          : await store.dispatch("sendMessageByEmail", { emails: [patient.value.ds_email], message: message.value });
      loading.submit = false;

      if (response?.status === 200) handleClose();
    }

    if (!_id) handleClose();
    else if (guide.value) searchPatients();

    watch(guide, searchPatients);

    return {
      ...{ contactType, patient, message, loading },
      ...{ handleClose, onSubmit },
      ...{ formatCPFToLGPD },
    };
  },
});

export default VGuideValidate;
