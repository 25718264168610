
import { defineComponent, onMounted, ref } from "vue";

const CFormTextArea = defineComponent({
  name: "CFormTextArea",
  emits: ["update:modelValue"],
  props: {
    elementID: { type: String, required: true },
    placeholder: { type: String, default: "" },
    width: { type: String, default: "100%" },
    rows: { type: String, default: "6" },
    hasError: { type: Boolean, default: false },
    hasAutofocus: { type: Boolean, default: false },
    isRequired: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
    modelValue: { required: true },
  },
  setup(props, { emit }) {
    const textarea = ref<HTMLInputElement | null>(null);

    function handleEmit(event: TextAreaEvent) {
      emit("update:modelValue", event?.target?.value);
    }

    onMounted(() => {
      if (props.hasAutofocus) textarea.value?.focus();
    });

    return { textarea, handleEmit };
  },
});

export interface TextAreaEvent extends Event {
  target: { value: string } & EventTarget;
}

export default CFormTextArea;
