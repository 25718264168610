import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = {
  key: 1,
  class: "c-form-textarea-before"
}
const _hoisted_3 = ["id", "rows", "value", "placeholder", "autofocus", "required", "disabled"]
const _hoisted_4 = {
  key: 2,
  class: "c-form-textarea-after"
}
const _hoisted_5 = {
  key: 3,
  class: "c-form-textarea-description"
}
const _hoisted_6 = {
  key: 4,
  class: "c-form-textarea-description"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(['c-form-textarea control', { hasError: _ctx.hasError, isDisabled: _ctx.isDisabled }]),
    style: _normalizeStyle({ width: _ctx.width })
  }, [
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          class: "c-form-textarea-label",
          for: _ctx.elementID
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_1))
      : _createCommentVNode("", true),
    (_ctx.$slots.before)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _renderSlot(_ctx.$slots, "before")
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("textarea", {
      ref: "textarea",
      id: _ctx.elementID,
      class: "c-form-textarea-textarea textarea is-primary py-3 px-4",
      rows: _ctx.rows,
      value: _ctx.modelValue,
      placeholder: _ctx.placeholder,
      autofocus: _ctx.hasAutofocus,
      required: _ctx.isRequired,
      disabled: _ctx.isDisabled,
      onInput: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleEmit && _ctx.handleEmit(...args)))
    }, null, 40, _hoisted_3),
    (_ctx.$slots.after)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "after")
        ]))
      : _createCommentVNode("", true),
    (_ctx.hasError)
      ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.hasError.description), 1))
      : (_ctx.$slots.description)
        ? (_openBlock(), _createElementBlock("small", _hoisted_6, [
            _renderSlot(_ctx.$slots, "description")
          ]))
        : _createCommentVNode("", true)
  ], 6))
}